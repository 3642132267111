<template>
  <div class="hero-banner-seven lg-container">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h1 class="hero-heading">
            It's not a fantasy. <p class="gradient">We are real</p>
          </h1>
          <p class="hero-sub-heading">
            Highly skilled and experienced professionals to accelerate and boost your technology projects.
          </p>
        </div>
        <div class="col-lg-6">
            <div class="illustration-container">
                <img src="../../assets/images/unicorns/unicorn.svg" alt="Unicorn" />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroArea",
  data() {
    return {
      logoSliderData: [
        {
          id: 1,
          img: require(`@/assets/images/logo/logo-21.png`),
        },
        {
          id: 2,
          img: require(`@/assets/images/logo/logo-22.png`),
        },
        {
          id: 3,
          img: require(`@/assets/images/logo/logo-23.png`),
        },
        {
          id: 4,
          img: require(`@/assets/images/logo/logo-24.png`),
        },
        {
          id: 5,
          img: require(`@/assets/images/logo/logo-25.png`),
        },
        {
          id: 6,
          img: require(`@/assets/images/logo/logo-21.png`),
        },
        {
          id: 7,
          img: require(`@/assets/images/logo/logo-24.png`),
        },
      ],
      breakpoints: {
        0: {
          itemsToShow: 2,
          snapAlign: "left",
        },
        576: {
          itemsToShow: 3,
          snapAlign: "left",
        },
        768: {
          itemsToShow: 4,
          snapAlign: "left",
        },
        992: {
          itemsToShow: 5,
          snapAlign: "left",
        },
      },
    };
  },
};
</script>
