<template>
  <div class="main-page-wrapper overflow-hidden">
    <HeaderSeven />
    <TermsConditionArea/>
    <Footer />
  </div>
</template>

<script>
import HeaderSeven from "../home-page2/HeaderSeven.vue";
import TermsConditionArea from './terms-condition-area.vue';
import Footer from "./Footer.vue";

export default {
  name:'TermsConditionsMain',
  components: { HeaderSeven, TermsConditionArea, Footer },
}
</script>