<template>
  <HomePage2Main/>
</template>

<script>
import HomePage2Main from '../components/home-page2';

export default {
  name:'HomePage2',
  components:{
    HomePage2Main,
  }
}
</script>