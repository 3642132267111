<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <HeaderSeven />
    <HeroArea />
    <Footer />
  </div>
</template>

<script>
import HeaderSeven from "./HeaderSeven.vue";
import HeroArea from "./Hero-area.vue";
import Footer from "./Footer.vue";

export default {
  name: "NoteTakingMain",
  components: {
    HeaderSeven,
    HeroArea,
    Footer,
  },
};
</script>
