<template>
  <div
    :class="`theme-main-menu sticky-menu theme-menu-five ${
      isSticky ? 'fixed' : ''
    }`"
  >
    <div class="d-flex align-items-center justify-content-center">
      <div class="logo">
        <router-link to="/">
          <img src="../../assets/images/unicorns/Iso_Gradient.svg" alt="Logo Unicorns" />
        </router-link>
      </div>

      <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
        <div class="nav-container">
          <button
            class="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
            @click="menuActive = !menuActive"
          >
            <span></span>
          </button>
          <div
            :class="
              menuActive
                ? 'navbar-collapse collapse ss'
                : 'navbar-collapse collapse'
            "
            id="collapsibleNavbar"
          >
            <div class="d-lg-flex justify-content-between align-items-center">
              <ul
                class="navbar-nav main-side-nav font-gordita"
                id="one-page-nav"
              >
                <li class="nav-item">
                  <a href="#about" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >About us</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="#talent" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Talent</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="#services" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Services</span
                    ></a
                  >
                </li>
                <li class="nav-item">
                  <a href="#contact" class="nav-link"
                    ><span @click.self="menuActive = !menuActive"
                      >Contact</span
                    ></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>

</script>
