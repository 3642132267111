<template>
  <TermsConditionsMain/>
</template>

<script>
import TermsConditionsMain from '../components/terms-conditions';

export default {
  name:'TermsConditions',
  components:{TermsConditionsMain}
}
</script>