<template>
  <div class="terms_and_policy">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#opt1">1. Legal Warning</a>
            </li>
            <!--<li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#opt2">2. Terms & Conditions</a>
            </li>-->
          </ul>
        </div>

        <div class="col-lg-8">
          <!-- Tab panes -->
          <div class="tab-content">
            <div v-for="(item,index) in terms_data" :key="index" :id="item.id" 
            :class="`tab-pane fade ${item.active ? 'active show' : ''}`">
              <h2 class="font-gilroy-bold">{{item.title}}</h2>
              <div class="update-date">{{item.data}}</div>
              <h3>{{item.title_1}}</h3>
              <p>{{item.text_1}}</p>
              <h3>{{item.title_2}}</h3>
              <p>{{item.text_2}}</p>
              <h3 v-if="item.id == 'opt1'">{{item.title_3}}</h3>
              <p v-if="item.id == 'opt1'">{{item.text_3}}</p>
              <p v-if="item.id == 'opt1'">{{item.text_4}}</p>
            </div>

          </div> <!-- /.tab-content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsConditionArea',
  data () {
    return {
      terms_data : [
        {
          id:'opt1',
          active:true,
          title:'Legal Warning',
          date : 'LAST UPDATED: 8/26/2020',
          title_1 : '1. Introduction',
          text_1 : `This page, together with our Privacy & Cookie Policy and the website Terms of Use tells you information about us and the legal terms and conditions (the "Terms") on which we make available to you our solution (the "Service").`,
          title_2 : '2. Information about company',
          text_2: 'TheUnicorns.io and any of their sub-domains is a site operated by Unicorns Solutions S.L. ("we", "our", "us"). We are registered in Spain under VAT number B14881593 and have our registered office at Bolero 4, 2A in Palma.',
          title_3: '3. Corporate purpose',
          text_3: 'a) The provision of professional services related to technological consulting and training, business and management consulting, including the preparation and execution of studies and projects, as well as the management, technical assistance, technology transfer, marketing and administration of such activities.',
          text_4: 'b) The design, development, integration, operation, maintenance, repair and commercialization of systems, solutions and products that make use of information technologies (informatics, electronics and communications), as well as any part or component thereof and any kind of related services.'
        }
      ]
    }
  }
}
</script>