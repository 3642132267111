import {createRouter, createWebHistory} from 'vue-router'
//import HomePage from '../pages/home-page';
import HomePage2 from '../pages/home-page2';
import TermsConditions from '../pages/terms-conditions';
//import ComingSoon from '../pages/coming-soon';
import NotFound from '../pages/404';


const routes = [
    /*{
        path: '/coming',
        name: 'ComingSoon',
        component: ComingSoon,
        meta: {
            title: 'Unicorns',
        },
    },
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage,
        meta: {
            title: 'Unicorns',
        },
    },*/
    {
        path: '/',
        name: 'HomePage2',
        component: HomePage2,
        meta: {
            title: 'Unicorns',
        },
    },
    {
        path: '/terms-conditions',
        name: 'TermsConditions',
        component: TermsConditions,
        meta: {
            title: 'Unicorns - Terms & Conditions',
        },
    },
    {
        path: '/not-found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'Not Found | Unicorns',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'Not Found | Unicorns',
        },
    },
   
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
